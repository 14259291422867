import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const IndexPage = props => {
  const quoteList = props.data.quotes

  return (
    <Layout>
      <Seo title="What People Say" />
      <div className="flex">
        <div className="sm:w-4/5">
          <ul>
            {quoteList.edges.map(({ node }, i) => (
              <li key={i} className="mb-12">
                <blockquote className="testimonial text-base text-shadow">
                  <span
                    className="italic"
                    dangerouslySetInnerHTML={{ __html: node.html }}
                  ></span>
                  <cite className="block not-italic text-xs mt-3">
                    {node.frontmatter.title}, {node.frontmatter.role}
                  </cite>
                </blockquote>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export const workQuery = graphql`
  query QuoteQuery {
    quotes: allMarkdownRemark(
      filter: { fields: { collection: { in: ["quotes"] } } }
      sort: { fields: fields___order }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            role
          }
        }
      }
    }
  }
`

export default IndexPage
